@import "modules/responsive-type.scss";

$primary: #1197c7;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerBg: #002b44;
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Playball');
@import url('https://fonts.googleapis.com/css?family=Rokkitt');
// font-family: 'Playball', cursive;
// font-family: 'Rokkitt', serif;
@font-face {
  font-family: 'Painter';
  src: url('../font/Painter.eot');
  src: url('../font/Painter.eot?#iefix') format('embedded-opentype'),
    url('../font/Painter.woff') format('woff'),
    url('../font/Painter.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
   font-family: 'Rokkitt', serif;
    font-size: 1rem;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 { 
  font-family: 'Rokkitt', serif;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 {
  font-family: 'Rokkitt', serif;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

// #terms_header h2{
//     visibility: hidden;

//     &:before {
//         content: 'Terms Of Use / Disclaimer:';
//         visibility: visible;
//     }
// }

// h3 {    
//     text-transform: lowercase;  

//     &:first-letter {
//         text-transform: uppercase;
//     }
// }



body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: url(../img/logo-bg.png);
    background-repeat: repeat-x;
    background-size: contain;

    .navbar-nav {
        >li>a {
            font-size: 1.5em;
            font-family: 'Painter';
            text-align: center;
            margin-top: 18px;
            display: flex;
            align-items: center;
            color: $wht;
            border-radius: $border-radius;
            transition-duration: 0.5s;

            @media (max-width: 991px) {
                font-size: 1em;
            }

            @media (max-width: 767px) {
                font-size: 1em;
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 15px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: #000;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 50%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow:auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $footerBg;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 300px;
    // padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 245px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 187px;
    }
    .navbar-toggle {
        margin-top: 8px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {

    @media (max-width: 335px){
        padding-top: 0px;
    }
    

    p {
        font-size: 1rem;
    }
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.marTop{
    margin-top: 25px;
}


.intro {

    .container-fluid {
        padding: 0px !important;
    }

    .video-wrapper {
        position: relative;
        .fullscreen-video {
            width: 100%;
            z-index: 1;
        }
        .title {
            margin: 0px auto;
            max-width: 100%;
        }  
        h1 {
            position:absolute;
            left: 28%;
            right:0%;
            top: 30%;
            bottom: 0%;
            font-family: 'Painter';
            font-size: 5rem;
            color: rgba(255, 255, 255, 1);

            @media (max-width:  1366px) {
                font-size: 4rem;
            }

            @media (max-width: 1080px) {
                font-size: 3.4rem;
            }

            @media (max-width: 1024px) {
                font-size: 3.5rem;
            }

            @media (max-width: 991px) {
                font-size: 3rem;
            }

            @media (max-width: 800px) {
                font-size: 2.8rem;
            }
            
            @media (max-width: 768px) {
                font-size: 2.5rem;
            }

            @media (max-width: 640px) {
                font-size: 2.2rem;
            }

            @media (max-width: 568px) {
                font-size: 2rem;
            }

            @media (max-width: 414px) {
                font-size: 1.6rem;
            }
            
            @media (max-width: 375px) {
                font-size: 1.5rem;
            }

            @media (max-width: 360px) {
                font-size: 1.4rem;
            }
            
            @media (max-width: 320px) {
                font-size: 1.2rem;
            }
        }
    }
}


.divider {
    margin-top: -5px;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img1.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 500px 0px;

    @media (max-width: 1200px) {
        background-position: 58% 50%;
        padding: 250px 0px;
    }


    @media (max-width: 991px) {
        background-position: 58% 50%;
        padding: 250px 0px;
    }


    @media (max-width: 767px) {
        padding: 250px 0px;
    }

    @media (max-width: 568px) {
        background-position: 59% 50%;
        padding: 50px 0px;
    }
    
    .topPad {
        padding-top: 25px;
    }
    
    .botPadMin {
        padding-bottom: 5px;
    }

    h1 {
        font-family: 'Painter';
        // font-size: 2rem;
        color: rgba(255, 255, 255, 0.75);

        @media (max-width: 568px){
            font-size: 1.2rem;
        }
    }
  
    i.fa {
        color: #fff;
        font-size: 4rem;
    }
}


.form {
  h1 {
    padding-top: 25px;
    // padding-left: 50px;
    font-family: 'Painter';
    // font-size: 100px;
    color: rgba(255, 255, 255, 0.75);
  }
  p {
    padding: 0px;
    font-family: 'Playball', cursive;
    //font-size: 25px;
    color: #fff;
  }
}

.divider2 {
    background-color: #090608;
    
    .overlay {
        padding: 0px;
        margin: 0px;

        img {
         border-radius: 5px;
        }

        h1 {
           font-family: 'Painter';
            // font-size: 40px;
            color: $wht;
            
            @media (max-width: 767px){
                // font-size: 25px;
            }
        }

        p {
            // font-size: 18px;
            color: $blk;
        }
    }

    .overlay .after{
        display: block;
        background: rgba(0, 0, 0, .5);
        position: absolute;
        top: 0;
        left: 4;
        width: 95%;
        height: 100%;
        // padding: 15px;
        // border-radius: 5px;
    
        @media (max-width: 1199px){
            left: 4;
            width: 97%;
        }

        @media (max-width: 991px){
            left: 4;
            width: 96%;
        }

        @media (max-width: 568px){
            left: 4;
            width: 94.7%;
        }

        @media (max-width: 414px){
            left: 4;
            width: 93%;
        }

        @media (max-width: 320px){
            left: 4;
            width: 91%;
        }

    }

    .toBottom {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%)
    }



  // padding: 0px 0px;
  // overflow: hidden;

    // .img4 {
    //     background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img4.jpg);
        // background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img4.jpg);
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: 50% 50%;
        //padding: 308px 0px;

    //     // @media (max-width: 1645px){
    //     //     background-position: 50% 50%;
    //     //     padding: 306px 0px;
    //     // }  


    //     // @media (max-width: 1400px){
    //     //     background-position: 50% 15%;
    //     //     padding: 308px 0px;
    //     // } 

    //     // @media (max-width: 1200px){
    //     //     background-position: 50% 15%;
    //     //     padding: 308px 0px;
    //     // } 


    //     // @media (max-width: 568px){
    //     //     background-position: 50% 50%;
    //     //     padding: 160px 0px 50px;
    //     // } 
    

    // .img5 {
    //   background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img5.jpg);
    //   background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img5.jpg);
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: 50% 50%;
    //   padding: 335px 0px;

        // @media (max-width: 1500px){
        //     background-position: 50% 50%;
        //     padding: 330px 0px
        // }  

        // @media (max-width: 1325px){
        //     background-position: 50% 50%;
        //     padding: 328px 0px;
        // }  


        // @media (max-width: 1300px){
        //     background-position: 50% 50%;
        //     padding: 328px 0px;
        // }  

        // @media (max-width: 1280px){
        //     background-position: 50% 50%;
        //     padding: 297px 0px;
        // }  


        // @media (max-width: 1200px){
        //     background-position: 50% 50%;
        //     padding: 330px 0px;
        // }  

        // @media (max-width: 568px){
        //     background-position: 50% 50%;
        //     padding: 160px 0px 50px;
        // }
  
      
  h1 {
    font-family: 'Painter';
    // font-size: 100px;
    color: rgba(255, 255, 255, 0.75);
  }
  
  p {
    padding: 0px 10px;
    font-family: 'Playball', cursive;
    // font-size: 22px;
    color: #fff;
  }
}


.outro h1 {
  font-family: 'Painter';
  // font-size: 100px;
  color: rgba(255, 255, 255, 0.75);
}

@media (max-width: 767px) {
  .outro h1 {
    // font-size: 50px;
  }
}

// .img2 {
//   background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img2.jpg);
//   background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img2.jpg);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: 50% 100%;
//   padding: 300px 0px;
// }

.img3 {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img3.jpg);
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding: 300px 0px;

    @media (max-width: 800px) {
      padding: 150px 0px;
    }
}



.img6 {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img6.jpg);
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 50, 85, 0.12)), url(../img/img6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 20%;
  padding:50px 0px 160px;

  @media (max-width: 767px){
    background-position: 14% 30%;
    padding: 0px 0px;
  }

  @media (max-width: 568px){
    background-position: 46% 30%;
    padding: 0px 0px;
  }
}


.btn {
  font-family: 'Quicksand', sans-serif;
  background-color: #002b44;
  font-size: 15px;
  padding: 1em 2em;
  color: #fafafa;
  &:hover {
    background-color: #007999;
    color: #fafafa;
    &:active, &:focus {
      background-color: #007999;
      color: #fafafa !important;
    }
  }
}

@media (max-width: 1366px) {
  .btn:hover {
    background-color: #007999;
    color: #fafafa;
  }
}